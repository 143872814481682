@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root { 
  --yellow-brand-color: #FEED01;
  --yellow-dark-shadow: #D0C302;
 }
 
a{
  text-decoration: none !important;
}
#enquire,
#services{
  scroll-margin-top:100px;
  scroll-behavior: smooth;
}
body{
  font-family: 'Poppins', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
}
html {
    --scrollbarBG:black;
    --thumbBG: yellow;
}
  body::-webkit-scrollbar {
    width: 10px;
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

/* ---- All Buttons */
.btn-submit{
  background: black !important;
  border: 1px solid black !important;
  color:var(--yellow-brand-color) !important;
  border-radius: 12px !important;
  transition: 0.3s !important;
  }
  .btn-submit:hover{
    background: var(--yellow-brand-color) !important;
    color:black !important;
    transform: translateY(-3px) !important;
  }

  .btn-enquire{
    background: var(--yellow-brand-color) !important;
    color: black !important;
    border: none !important;
    border-radius: 12px !important;
    transition: 0.3s !important;
  }
  .btn-enquire:hover{
    transform: translateY(-5px);
  }


 
  /* ------------ Navbar CSS ------------------ */
  
  .navbarTransperent{
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
  
.navbarBlack{
    background-color: #000;
    transition: 0.3s ease-in-out;
}
  nav{
    position: fixed;
    width:100%;
    /* background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 10px;
    z-index: 1000;
   }
  
  
   nav ul{
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
  
   nav ul li {
    letter-spacing: 1px;
    
    font-weight: 400;
    padding: 2px 10px;
    transition: 0.2s;
    display: inline-block;
    color: var(--yellow-brand-color);
  }
  
  nav .mobile-nav li{
    font-size: 30px !important;
  }
  nav ul li:hover{
    transform: translateY(-3px);
    color: white;
   }
    
  @media(max-width:992px){
    
  .lg-nav{
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap:2rem;
    border-radius: 0px;
    inset:0 0 0 0%;
    z-index: 1000;
    flex-direction: column;
    /* padding: min(20vh, 10rem) 2em; */
    transform: translateX(100%);
    transition: 0.3s;
  list-style: none;
  margin: 0;
  background: black;
    }
    .mobile-nav li {
      margin-bottom: 15px;
      color: yellow;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .mobile-nav[data-visible="true"]{
      transform: translateX(0%);
     }

   .mobile-nav-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all .5s ease-in-out;

    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
    z-index: 9999;
    
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 36px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
   
  }
  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }
  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
   
  }
  
  @media(min-width:992px){
  .lg-nav{
    display: flex;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-nav-toggle{
    display: none;
  }
  }
  
/* ----- Home Banner Carousel CSS ------ */
.banner,
.mobile-banner{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.banner h1{
  font-family: 'Anton', sans-serif;
font-style: italic;
  font-size: 80px;
  color: var(--yellow-brand-color) !important;
  letter-spacing: 2px;
  margin:0px;
  text-shadow:  1px 2px black,
                3px 3px white,
                2px 2px white;
   -webkit-transition: all 0.12s ease-out;
   -moz-transition:    all 0.12s ease-out;
   -ms-transition:     all 0.12s ease-out;
   -o-transition:      all 0.12s ease-out;
}

.mobile-banner h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 80px;
  color: black !important;
  letter-spacing: 2px;
  margin:0px;
  text-shadow:  1px 2px var(--yellow-brand-color),
                3px 3px white,
                2px 2px black;
   -webkit-transition: all 0.12s ease-out;
   -moz-transition:    all 0.12s ease-out;
   -ms-transition:     all 0.12s ease-out;
   -o-transition:      all 0.12s ease-out;
}

.MobileModel div{
  padding: 0px 15px;
  position: absolute;
  height: 50vh;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
 align-items: center;


}
.MobileModel div h1{
  font-size: 60px;

}


.carousel-item.active h1 {
    animation: fadeInleft 0.8s ease forwards;
  }
@keyframes fadeInleft {
  0% {
      opacity: 0;
      transform: translateY(-30px) ;
  }
  100% {
      opacity: 1;
      transform: translateY(0px) rotate(-5px);
  }
}

@media(min-width:992px){
  .banner{
    display: block;
  }
  .mobile-banner{
    display: none;
  }
}
/* -- Mobile Model ---*/
@media(max-width:992px){
.mobile-banner{
  display: block;
}
.banner{
  display: none;
}
}



 
/* ---- THE PACK Container Started ---- */
.thepack h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 50px;
  letter-spacing: 1px;
  text-shadow:  1px 2px var(--yellow-brand-color);
-webkit-transition: all 0.12s ease-out;
-moz-transition:    all 0.12s ease-out;
-ms-transition:     all 0.12s ease-out;
-o-transition:      all 0.12s ease-out;
}

.thepack .card{
  text-align: center;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 40px 50px -10px, rgba(0, 0, 0, 0.1) 0px 30px 40px -20px;
  border-radius: 15px;
  transition: 0.3s;
  border:none;
  padding: 12px 20px;
}
.thepack .img-bg{
  width: 90px;
  height: 90px;
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.thepack .card h4{
  font-weight: 500;
  margin: 16px 0px 6px 0px;
}
.thepack .card:hover{
  transform: translateY(-10px) !important;
  background: #000;
  color: white;
  cursor: pointer;
  transition: 0.3s !important;
}
.thepack .card:hover .img-bg{
  background: yellow;
}
.thepack .card:hover .thepack-icon{
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(268deg) brightness(93%) contrast(96%);
}
.thepack .thepack-icon{
  filter: invert(85%) sepia(14%) saturate(3192%) hue-rotate(3deg) brightness(108%) contrast(104%);
  width: 50px;
  margin: 0px auto;
  transition: 0.2s;
}
.thepack .cs-item span{
  height: 90px;
	width: 90px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 50%;
	display: inline-block;
	color: var(--yellow-brand-color);
	line-height: 90px;
	text-align: center;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.thepack .cs-item:hover span{
  background: var(--yellow-brand-color);
	color: #ffffff;
}

/* --- Beast Section CSS ----*/
.Beast h1{
  font-weight: 700;
}

/* ----- Testimonials ----- */
.Testimonials{
  background: url('./Assets/TestBg.jpg') rgba(0, 0, 0, 0.5) center center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}
.Testimonials img{
  border-radius: 50%;
  border: 3px solid white;
}
.Testimonials h5{
  color:white;
  margin-top: 5px;
}
.Testimonials h1{
  font-weight: 700;
  color:var(--yellow-brand-color);
}
.Testimonials h3,
.Testimonials p{
  color: white;
  text-shadow: #000000 1px 0 8px;

}

/* ------------------------------ FAQs Accordion ------------------------------*/

.faq h1{
  font-weight: 700;
}
.faq .accordion-item{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 border: none;
 border-radius: 0px 0px 12px 12px !important;
 margin-bottom: 15px;
}
.faq .accordion-button{
  border-radius: 0px 0px 12px 12px !important;
  border: none;
}
.faq .accordion-button:not(.collapsed){
  color: inherit;
  background:black;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--yellow-brand-color);
}

.faq button.accordion-button:not(.collapsed)::after{
 filter:brightness(0%) invert(100%);
 color: white;
}

.faq .accordion-button:focus{
  box-shadow: inherit;
}

/* ------------------------------- Our Services --------------------------------- */
.services .header{
  background: url('./Assets/HeaderBg.jpg') center center no-repeat;
  background-size: cover;
  height: 100vh;
}
.services .header h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 2px;
  color: white;
}
.services .header h4{
    color:#d1cfcf;
}
.services .card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  transition: 0.3s;
  border:none;
}

.services .card img{
  border-radius: 15px 15px 0px 0px;
}

.services .card:hover{
  transform: scale(0.9) !important;
  cursor: pointer;
}

/* -------------------------------- About US ------------------------------------- */
.about .header{
  background: url('./Assets/TestBg.jpg') rgba(0, 0, 0, 0.2) center center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  height: 100vh;
}
.about .header h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 2px;
  color: white;
  text-shadow:  1px 2px black,
                1px 0px 10px black;
-webkit-transition: all 0.12s ease-out;
-moz-transition:    all 0.12s ease-out;
-ms-transition:     all 0.12s ease-out;
-o-transition:      all 0.12s ease-out;
}

.about .header h4{
  color:#d1cfcf;
  text-shadow: 1px 0px 10px black;
}

.about .vision .heading{
  font-size: 30px;
  font-weight: 700;
  color:black;
}
.about .vision img{
  border-radius: 15px;
}

.about .vision .box{
  background: linear-gradient(145deg,yellow,var(--yellow-brand-color));
  border-radius: 15px;
  padding: 15px 20px;
  z-index: 999;
}

@media(max-width:768px){
  .about .vision .vision-left,
  .about .vision .mission-right{
    margin-top: -50px;
  }
}

@media(min-width:768px){
  .about .vision .vision-left{
    background: linear-gradient(180deg,yellow,var(--yellow-brand-color));
    padding: 15px 20px;
    border-radius: 15px;
    margin-left: -100px;
  }
}

@media(min-width:768px){
  .about .vision .mission-right{
    background: linear-gradient(180deg,yellow,var(--yellow-brand-color));
    padding: 15px 20px;
    border-radius: 15px;
    margin-right: -100px;
  }
}

.newsCard .img-bg{
  width: 45px;
  height: 45px;
  background: yellow;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsCard h6{
  color: var(--yellow-brand-color);
}
.newsCard .thepack-icon{
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(268deg) brightness(93%) contrast(96%);
}

.newsCard {
  position: relative;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  color:#FFFFFF;
  overflow: hidden;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.newsCaption {
 
  position: absolute;
  top: auto;
  bottom: 0%;
  opacity: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  padding: 15px;
  -webkit-transform: translateY(75%);
          transform: translateY(75%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;
}

.newsCaption-title {
  margin-top: 0px;
}

.newsCaption-content {
  margin-top:20px;
}
.newsCard .newsCaption-content span{
  color: var(--yellow-brand-color);
}
/* 
.newsCaption-link {
  color: #fff;
  text-decoration: underline;
  opacity: .8;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
          transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
}
.newsCaption-link:hover {
  opacity: 0.65;
} */

.news-Slide-up:hover .newsCaption {
  opacity: 1;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.4s;
  transition: opacity 0.1s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s;
}

.about .team h1,
.gallery h1{
  font-weight: 700;
}



/* -------------------------------- About US ------------------------------------- */
.contact .header{
  background: url('./Assets/contactus.jpg') rgba(0, 0, 0, 0.4) center center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  height: 100vh;
}
.contact .header h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 2px;

  color: white;
  text-shadow:  1px 2px black,
           1px 0px 10px black;
-webkit-transition: all 0.12s ease-out;
-moz-transition:    all 0.12s ease-out;
-ms-transition:     all 0.12s ease-out;
-o-transition:      all 0.12s ease-out;
}

.contact .header p{
  color:#FFFFFF;
  text-shadow:1px 0px 10px black;
  font-weight: 300;
  text-shadow: #000;
  font-size: 20px;

}

.contact #enquire h2,
.contact .location h2{
  font-weight: 700;
}

.contact form input,
.contact form textarea,
.contact form select
{
 border: 1px solid black;
 border-radius: 12px;
}
.contact .form-control:focus,
.contact form select:focus{
border-color: transparent;
  box-shadow: 0 0 0 0.2rem var(--yellow-brand-color);
}
@media(max-width:992px){
  .contact form textarea{
    height: 80px !important;
  }
}

@media(min-width:992px){
  .contact form textarea{
    height: 140px !important;
  }
}

/* --- Footer CSS ---*/
footer{
  background: black;
  color: var(--yellow-brand-color);
  padding: 20px 0px 30px 0px;
}

footer .footerTop{
  background: linear-gradient(145deg,yellow,var(--yellow-brand-color));
  margin-top: -50px;
}
@media(max-width:768px){
  footer .footerTop{
    margin-top: -80px;
  }
}
footer .footerTop h5{
  color: black;
}

footer p{
  color: white;
}

footer .sitemap{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
footer .sitemap a{
  transition: 0.3s !important;
  color: white;
  width: max-content;
}
footer .sitemap a:hover{
  transform: translateX(-10px);
  color: var(--yellow-brand-color);
}


.NewClass{
  width:100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto auto;
}
@media(max-width:992px){
  .NewClass{
    width:100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: auto auto;
  }
}
.NewClass img{
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 12px;
}

#root{
  position: relative;
}
.whatsapp{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  right: 3%;
  bottom:5%;
  z-index: 1000;
  background: linear-gradient(45deg, #2da72b, #36d933);
  color: white;
  transition: 0.3s;
}
.whatsapp:hover{
  color: white;
  transform: scale(1.20);
}

